import styled from 'styled-components'

export const Wrapper = styled.div`
	align-items: center;
	a {

		border: 2px black solid;
		border-radius:4px;
		padding:.5rem;
		font-weight:400;

		color: black;
		text-decoration: none;
		transition: background 1s;
		:hover{

			background-color:white;

		}
	}

	${({ desktop }) =>
		desktop
			? `
			@media (max-width: 960px) {
					display: none;
			}

			a {
					margin-right: 1rem;

					&:last-child {
							margin-right: unset;
					}
			}
		`
			: `
			padding: 3rem;
			display: flex;
			flex-direction: column;

			a {
					margin-bottom: 1rem;

					&:last-child {
							margin-bottom: unset;
					}
			}
	`}
`
