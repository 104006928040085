module.exports = {
	defaultTitle: 'Austin Green New Deal',
	logo: './static/favicon/favicon.png',
	author: 'Robert Foster',
	url: '',
	legalName: 'Robert Foster',
	defaultDescription: 'Austin Green New Deal',
	socialLinks: {
		twitter: '',
		github: '',
		linkedin: '',
		instagram: '',
		youtube: '',
		google: '',
	},
	googleAnalyticsID: 'UA-88875900-4',
	themeColor: '#6b63ff',
	backgroundColor: '#6b63ff',
	social: {
		facebook: '',
		twitter: '',
	},
	address: {
		city: 'Austin',
		region: 'Texas',
		country: 'USA',
		zipCode: '78705',
	},
	contact: {
		email: 'email',
		phone: 'phone number',
	},
	foundingDate: '2019',
}
